// eslint-disable-next-line import/prefer-default-export
export const getSelectedFilters = (
  tag: PageContent.TTagWithImageAndSubTitle,
  currentFilters: Array<string | number>
) => {
  const { id } = tag;
  const foundIndex = currentFilters.indexOf(Number(id));
  const selectedFilters = foundIndex >= 0 ? [] : [id];

  return { foundIndex, selectedFilters };
};
